import React, {useEffect, useState} from 'react';
import Axios from 'axios';
import Moment from 'moment-timezone';
import { Row, Col, Table, Button } from 'react-bootstrap';
import {
    DatatableWrapper,
    Filter,
    Pagination,
    TableBody,
    TableHeader
} from "react-bs-datatable";

import { AppConstants } from '../AppConstants';
import AlertComponent from '../components/AlertComponent';
import LoadingComponent from '../components/LoadingComponent';

import { logoutUser } from '../helper/global-helper';

function ViewReports({data}) {
    const dbBaseUrl = AppConstants().db_base_url;

    // loading and message display
    const [displayMsg, setDisplayMsg] = useState(null);
    const [showLoading, setShowLoading] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState('');

    const [reportList, setReportList] = useState([]);

    const STORY_HEADERS = [
        {
          prop: "client_name",
          title: "Client Name",
          isFilterable: true,
          isSortable: true
        },
        {
          prop: "report",
          title: "Report",
          isFilterable: true,
          isSortable: true
        },
        {
          prop: "report_date",
          title: "Report Date",
          isFilterable: true,
          isSortable: true
        },
        {
          prop: "cell",
          title: ""
        }
    ];

    function handleGetAddressReports(){
        setShowLoading(true);
        setLoadingMsg('Retrieving all inspection reports. Please wait.');

        Axios.defaults.headers.common['x-access-token'] = localStorage.getItem("token")
        Axios.post(dbBaseUrl + "citizens-get-address-reports", {
            userId: localStorage.getItem('id'),
            searchAddress: data.address.trim()
        })
        .then((response) => {
            setShowLoading(false);

            if( response.data.type === 'success' ){
                buildTable(response.data.resultData)
            } else {
                setDisplayMsg(response.data);
                if( response.data.code === 2 ){
                    logoutUser(response.data.message);
                }
            }
        })
        .catch(error => console.log(error));
    }

    const buildTable = (resData) => {
        let reportsTbl = [];

        if( resData !== undefined && resData !== null ){
            for( let x = 0; x < resData.length; x++ ){
                const reportLink = AppConstants().bucket_url + resData[x].inspection_report_path + resData[x].inspection_report_filename;
                const dateInspected = resData[x].inspection_report_type === 'Four Point' ? Moment(resData[x].fp_date_created).tz('America/New_York').format('MM-DD-YYYY')
                : resData[x].inspection_report_type === 'Commercial Roof Certification' ? Moment(resData[x].crc_date_created).tz('America/New_York').format('MM-DD-YYYY')
                : resData[x].inspection_report_type === 'Radon' ? Moment(resData[x].radon_date_created).tz('America/New_York').format('MM-DD-YYYY')
                : resData[x].inspection_report_type === 'Roof Certification' ? Moment(resData[x].rc_date_created).tz('America/New_York').format('MM-DD-YYYY')
                : resData[x].inspection_report_type === 'VA WDO' ? Moment(resData[x].vwdo_date_created).tz('America/New_York').format('MM-DD-YYYY')
                : resData[x].inspection_report_type === 'WDO' ? Moment(resData[x].wdo_date_created).tz('America/New_York').format('MM-DD-YYYY')
                : resData[x].inspection_report_type === 'Wind Mitigation Type II and III' ? Moment(resData[x].wmb_date_created).tz('America/New_York').format('MM-DD-YYYY')
                : resData[x].inspection_report_type === 'Wind Mitigation' ? Moment(resData[x].wm_date_created).tz('America/New_York').format('MM-DD-YYYY')
                : 'N/A';

                reportsTbl.push(
                    {
                        client_name: resData[x].applicant_firstname + ' ' + resData[x].applicant_lastname,
                        report: resData[x].inspection_report_type,
                        report_date: dateInspected,
                        cell: 
                            <>
                                <Button className='btn-primary' style={{marginRight: 5, marginBottom: 5}} onClick={() => window.open(reportLink, '_blank')}>View</Button>
                                <Button className='btn-success' style={{marginBottom: 5}} onClick={() => downloadReportFile(resData[x].inspection_report_id)}>Download Report and Photos</Button>
                            </>
                    }
                )
            }
        }
  
        setReportList(reportsTbl)
        return reportsTbl;
    }

    function downloadReportFile(reportId){
        setShowLoading(true);
        setLoadingMsg('Preparing report file to be downloaded. Please wait.');

        Axios.defaults.headers.common['x-access-token'] = localStorage.getItem("token")
        Axios.post(dbBaseUrl + "citizens-download-report-file", {
            userId: localStorage.getItem('id'),
            reportId: reportId,
            address: data.address.trim()
        })
        .then((response) => {
            setShowLoading(false);

            if( response.data.type === 'success' ){
                window.open(dbBaseUrl + response.data.file, 'Download');
            } else {
                setDisplayMsg(response.data);
                if( response.data.code === 2 ){
                    logoutUser(response.data.message);
                }
            }
        })
        .catch(error => console.log(error));
    }

    useEffect(() => {
        handleGetAddressReports();
    //eslint-disable-next-line
    }, [])

    return (
        <div className='container mt-4 mb-4'>
            <h3 className='primary text-center'>{data.address.toUpperCase()}</h3>
            <hr/>
            
            {
                displayMsg !== null ? 
                    <AlertComponent message={displayMsg.message} color={`${displayMsg.type === 'error' ?  'danger' : displayMsg.type === 'success' ? 'success' : ''}`} textColor='primary'  />
                : null
            }

            <button className='btn btn-warning' onClick={() => data.setSelectAddressEmpty()}>Go Back</button>

            <Row className='mt-5 mb-3'>
                <DatatableWrapper
                    body={reportList}
                    headers={STORY_HEADERS}
                    paginationOptionsProps={{
                        initialState: {
                            rowsPerPage: 20,
                        }
                    }}
                    sortProps={{
                        initialState: {
                          order: "desc",
                          prop: "report_date",
                        },
                    }}
                >
                <Row className="mb-4 p-2">
                    <Col className="d-flex flex-col justify-content-end align-items-end datatable-filter">
                        <Filter placeholder='Type to search' />
                    </Col>
                    <Col className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0">
                    </Col>
                    <Col className="d-flex flex-col justify-content-end align-items-end">
                        <Pagination />
                    </Col>
                </Row>
                <Table className='primary'>
                    <TableHeader />
                    <TableBody className='primary' />
                </Table>
                <Col className="d-flex flex-col justify-content-end align-items-end">
                    <Pagination />
                </Col>
                </DatatableWrapper>
            </Row>

            <LoadingComponent 
                show={showLoading}
                onHide={() => setShowLoading(false)}
                message = {loadingMsg}
                redirect = 'false'
            />
        </div>
    )
}

export default ViewReports