
export function isValidEmail(email, callback) {
    const emailRegExp = /\S+@\S+\.\S+/
    
    callback(emailRegExp.test(email)) ;
}

export function logoutUser(type){
    localStorage.clear()
    window.location.replace('?session=' + type)
}
