import React, {useState} from 'react';
import Axios from 'axios';
import { Image } from 'react-bootstrap';

import { AppConstants } from '../../AppConstants';
import AlertComponent from '../../components/AlertComponent';
import LoadingComponent from '../../components/LoadingComponent';

function ResetPassword() {
    const dbBaseUrl = AppConstants().db_base_url;
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');

    // loading and message display
    const [displayMsg, setDisplayMsg] = useState(null);
    const [showLoading, setShowLoading] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState('');

    const [formSubmit, setFormSubmit] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPw, setConfirmPw] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    function handleChangePassword(){
        setFormSubmit(true);
        setShowLoading(true);
        setLoadingMsg('Updating password. Please wait.');

        if( password === '' || password === null ){
            setShowLoading(false);
            return setDisplayMsg({type: 'error', message: 'Please enter your new password'})
        }
        if( confirmPw === '' || confirmPw === null ){
            setShowLoading(false);
            return setDisplayMsg({type: 'error', message: 'Please confirm your new password'})
        }
        if( confirmPw !== password ){
            setShowLoading(false);
            return setDisplayMsg({type: 'error', message: 'Passwords do not match'})
        }

        Axios.defaults.headers.common['x-access-token'] = token;
        Axios.post(dbBaseUrl + "citizens-reset-inspector-password", {
            password: password
        })

        .then((response) => {
            setFormSubmit(false);
            setDisplayMsg(response.data);
            setShowLoading(false);
            if( response.data.type === 'success' ){
                setTimeout(() => {
                    window.location.replace('/');
                }, 2000)
            }
        })
        .catch(error => console.log(error));
    }

    return (
        <div className='main-container content-centered container'>
            <Image src='/citizens-logo.png' className='mb-4' height={100} />

            <div id='login-container'>
                <h1 className='primary'>Reset Password</h1>

                {
                    displayMsg !== null ? 
                        <AlertComponent message={displayMsg.message} color={`${displayMsg.type === 'error' ?  'danger' : displayMsg.type === 'success' ? 'success' : ''}`} textColor='primary'  />
                    : null
                }

                <form className='mb-2' onKeyUp={(e) => e.key === 'Enter' && handleChangePassword()}>
                    <div className="input-group mb-4">
                        <span className="input-group-text"><i className={`fa-solid ${showPassword ? 'fa-eye' : 'fa-lock'}`}></i></span>
                        <input type={showPassword ? 'text' : 'password'} className={`form-control ${formSubmit && password === '' && 'is-invalid'}`} onChange={el => setPassword(el.target.value.trim())} placeholder="Enter your new password" aria-label="Password" autoComplete="on" />
                    </div>
                    <div className="input-group">
                        <span className="input-group-text"><i className={`fa-solid ${showPassword ? 'fa-eye' : 'fa-lock'}`}></i></span>
                        <input type={showPassword ? 'text' : 'password'} className={`form-control ${formSubmit && confirmPw === '' && 'is-invalid'}`} onChange={el => setConfirmPw(el.target.value.trim())} placeholder="Confirm your password" aria-label="Confirm Password" autoComplete="on" />
                    </div>
                </form>

                <p className='primary pointer text-right italic mt-2' onClick={(e) => setShowPassword(!showPassword)} >Show Password</p>
                <button className='btn-primary mt-2' onClick={() => handleChangePassword()}>Reset Password</button>
            </div>

            <LoadingComponent 
                show={showLoading}
                onHide={() => setShowLoading(false)}
                message = {loadingMsg}
                redirect = 'false'
            />
        </div>
    )
}

export default ResetPassword