import { createSlice } from '@reduxjs/toolkit';

const initialStateValue = {
    value: {
        id: localStorage.getItem("id"),
        token: localStorage.getItem("token")
    } 
}

export const userSlice = createSlice({
    name: "user",
    initialState: initialStateValue,
    reducers: {
        setLogin: ( state, action ) => {
            state.value = action.payload
        },
        setLogout: ( state ) => {
            state.value = initialStateValue
        }
    }
});

export const { setLogin, setLogout } = userSlice.actions;

export default userSlice.reducer;
