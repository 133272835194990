import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Sidebar from './app/components/Sidebar';
import Dashboard from './app/pages/Dashboard';

/* AUTH */
import LoginPage from './app/pages/auth/LoginPage';
import ForgotPassword from './app/pages/auth/ForgotPassword';
import ResetPassword from './app/pages/auth/ResetPassword';

/* SETTINGS */
import Settings from './app/pages/Settings';

import ViewLogs from './app/pages/ViewLogs';

function App() {
	const user = useSelector((state) => state.value)

	if( user.token !== null ){
		return(
			<BrowserRouter>
				<Sidebar>
					<Routes>
						<Route exact path="/" element={<Dashboard /> } />
						<Route path="/settings" element={<Settings /> } />
						<Route path="/view-logs" element={<ViewLogs /> } />
						<Route path="*" element={<Dashboard /> } />
					</Routes>
				</Sidebar>
			</BrowserRouter>
		)
	} else {
		return(
			<BrowserRouter>
				<Routes>
					<Route exact path="/" element={<LoginPage /> } />
					<Route path="/forgot-password" element={<ForgotPassword /> } />
					<Route path="/reset-password" element={<ResetPassword /> } />
					<Route path="*" element={<LoginPage /> } />
				</Routes>
			</BrowserRouter>
		)
	}
}

export default App;
