export const AppConstants = () => {
    let appName = 'Citizens-HIT';

    let _baseURL = process.env.REACT_APP_DB_BASE_URL;
    let _baseDBURL = process.env.REACT_APP_DB_BASE_URL;
    let _awsBucketUrl = process.env.REACT_APP_BUCKET_URL;

    const monthList = [
        { value: "1", label: "January"},
        { value: "2", label: "February"},
        { value: "3", label: "March"},
        { value: "4", label: "April"},
        { value: "5", label: "May"},
        { value: "6", label: "June"},
        { value: "7", label: "July"},
        { value: "8", label: "August"},
        { value: "9", label: "September"},
        { value: "10", label: "October"},
        { value: "11", label: "November"},
        { value: "12", label: "December"}
    ]

    return {
        'base_url' : _baseURL,
        'db_base_url': _baseDBURL,
        'bucket_url': _awsBucketUrl,
        'app_name': appName,
        'emailRegExp': /\S+@\S+\.\S+/,
        'month_list': monthList
    }
}