import React from 'react';
import { Modal } from 'react-bootstrap';

import { BallTriangle } from  'react-loader-spinner'

function LoadingComponent(props) {
    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
            >
            <Modal.Body className='loading-modal-body'>
                <BallTriangle
                    height={100}
                    width={100}
                    radius={5}
                    color="#90C2E7"
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle={{justifyContent: 'center'}}
                    visible={true}
                />
                <p className='primary mt-5 mb-2'>{props.message}</p>
                {
                    props.redirect === 'true' ? <p className='mt-3 mb-3 italic'>Redirecting...</p> : null
                }
            </Modal.Body>
        </Modal>
    )
}

export default LoadingComponent