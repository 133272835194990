import React, {useState, useEffect} from 'react'
import Axios from 'axios';
import Moment from 'moment-timezone';
import { Button, Row, Col, Table } from 'react-bootstrap';
import Select from 'react-select';
import {
    DatatableWrapper,
    Filter,
    Pagination,
    TableBody,
    TableHeader
} from "react-bs-datatable";

import { AppConstants } from '../AppConstants';
import AlertComponent from '../components/AlertComponent';
import LoadingComponent from '../components/LoadingComponent';

import { logoutUser } from '../helper/global-helper';

function ViewLogs() {
    const dbBaseUrl = AppConstants().db_base_url;

    // loading and message display
    const [displayMsg, setDisplayMsg] = useState(null);
    const [showLoading, setShowLoading] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState('');

    const currYear = Moment(new Date()).tz('America/New_York').format('YYYY');
    const currMonth = Moment(new Date()).tz('America/New_York').format('MM');
    const currMonthName = Moment(new Date()).tz('America/New_York').format('MMMM');

    const [viewLogsMonth, setViewLogsMonth] = useState({label: currMonthName, value: currMonth});
    const [viewLogsYear, setViewLogsYear] = useState({label: currYear, value: currYear});
    const [recordYearList, setRecordYearList] = useState([{label: currYear, value: currYear}]);
    const [viewRecordsList, setViewRecordsList] = useState([]);

    const RECORDS_TBL_HEADER = [
        {
            prop: "address_viewed",
            title: "Address",
            isFilterable: true,
            isSortable: true
        },
        {
            prop: "date_viewed",
            title: "Date Viewed",
            isFilterable: true,
            isSortable: true
        },
    ];

    function getViewLogsMonthYear(){
        const startingYear = 2023;
        const getYear = Moment(new Date()).tz('America/New_York').format("YYYY");

        let recordYears = [];

        for( let x = startingYear; x <= getYear; x++ ){
            recordYears.push({label: x, value: x})
        }

        setRecordYearList(recordYears);
        searchViewLogs();
    }

    function searchViewLogs(){
        setDisplayMsg(null)
        setLoadingMsg('Retreiving view logs. Please wait.')
        setShowLoading(true)

        Axios.defaults.headers.common['x-access-token'] = localStorage.getItem("token")
        Axios.post(dbBaseUrl + "get-citizens-view-logs-specific-user", {
            userId: localStorage.getItem('id'),
            viewLogsMonth: viewLogsMonth.value,
            viewLogsYear: viewLogsYear.value
        })
        .then((response) => {
            if( response.data.type === 'error' ){
                window.scrollTo(0,0);
                setDisplayMsg(response.data);
                setViewRecordsList([]);
                if( response.data.code === 2 ){
                    logoutUser(response.data.message);
                }
            } else {
                buildRecordsTable(response.data.resultData)
            }
            setShowLoading(false)
        })
        .catch(error => console.log(error));
    }

    const buildRecordsTable = (resData) => {
        let recordsTbl = [];
        if( resData !== undefined ){
          for( let x = 0; x < resData.length; x++ ){
            recordsTbl.push(
              {
                fullname: resData[x].fullname,
                email: resData[x].email,
                address_viewed: resData[x].address_viewed.toUpperCase(),
                date_viewed: Moment(resData[x].date_viewed).tz('America/New_York').format('MM-DD-YYYY HH:mm:ss'),
              }
            )
          }
        }
  
        setViewRecordsList(recordsTbl)
        return recordsTbl;
    }

    useEffect(() => {
        getViewLogsMonthYear();
    //eslint-disable-next-line        
    }, [])

    return (
        <div className='main-container container'>
            <h1 className='secondary bold'>View Logs</h1>
            <hr/>

            {
                displayMsg !== null ? 
                    <AlertComponent message={displayMsg.message} color={`${displayMsg.type === 'error' ?  'danger' : displayMsg.type === 'success' ? 'success' : ''}`} textColor='primary'  />
                : null
            }

            <div className='container'>
                <Button className='btn-danger mb-4' onClick={() => {
                    setDisplayMsg(null);
                    setViewRecordsList([]);
                }}>Go Back</Button>
                <Row>
                    <p className='primary italic'>Select year and month to filter view logs.</p>
                    <Col
                        md={12}
                        lg={4}
                        className='mb-3'
                    >
                        <Select
                            defaultValue={viewLogsYear}
                            onChange={(selected) => setViewLogsYear(selected) }
                            options={recordYearList}
                        />
                    </Col>
                    <Col
                        md={12}
                        lg={4}
                        className='mb-3'
                    >
                        <Select
                            defaultValue={viewLogsMonth}
                            onChange={(selected) => setViewLogsMonth(selected) }
                            options={AppConstants().month_list}
                        />
                    </Col>
                    <Col
                        md={12}
                        lg={4}
                        className='mb-4'
                    >
                        <Button className='btn-primary' onClick={() => searchViewLogs()}>Search</Button>
                    </Col>
                </Row>

                <Row className='mt-5 mb-3'>
                    <DatatableWrapper
                        body={viewRecordsList}
                        headers={RECORDS_TBL_HEADER}
                        paginationOptionsProps={{
                            initialState: {
                                rowsPerPage: 20,
                            }
                        }}
                        sortProps={{
                            initialState: {
                              order: "desc",
                              prop: "date_viewed",
                            },
                        }}
                    >
                    <Row className="mb-4 p-2">
                        <Col className="d-flex flex-col justify-content-end align-items-end datatable-filter">
                            <Filter placeholder='Type to search' />
                        </Col>
                        <Col className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0">
                        </Col>
                        <Col className="d-flex flex-col justify-content-end align-items-end">
                            <Pagination />
                        </Col>
                    </Row>
                    <Table>
                        <TableHeader />
                        <TableBody />
                    </Table>
                    <Col className="d-flex flex-col justify-content-end align-items-end">
                        <Pagination />
                    </Col>
                    </DatatableWrapper>
                </Row>
            </div>

            <LoadingComponent 
                show={showLoading}
                onHide={() => setShowLoading(false)}
                message = {loadingMsg}
                redirect = 'false'
            />
        </div>
    )
}

export default ViewLogs