import React from 'react'

import Alert from 'react-bootstrap/Alert'

function AlertComponent(props) {
    const { message, color, textColor, center, right, md } = props;

    const ALERT_BG_COLOR = 
        color === 'primary' ? 'rgba(0,48,96, 0.2)' :
        color === 'secondary' ? 'rgba(144,194,231, 0.2)' :
        color === 'support' ? 'rgba(206,211,220, 0.2)' :
        color === 'success' ? 'rgba(15,182,11,0.2)' :
        color === 'warning' ? 'rgba(247,127,0, 0.2)' :
        color === 'danger' ? 'rgba(226,28,28, 0.2)' :
        'rgba(0,48,96, 0.2)';

    const ALERT_TEXT_COLOR = 
        textColor === 'primary' ? '#003060' :
        textColor === 'secondary' ? '#90C2E7' :
        textColor === 'support' ? '#CED3DC' :
        textColor === 'success' ? '#0FB60B' :
        textColor === 'warning' ? '#F77F00' :
        textColor === 'danger' ? '#E21C1C' :
        textColor === 'white' ? '#fff' :
        '#000';

    const ALERT_TEXT_ALIGN = 
        center ? 'center' :
        right ? 'right' :
        'left';

    const ALERT_TEXT_SIZE = 
        md ? 18
        : 14

  return (
    <Alert style={{
        backgroundColor: ALERT_BG_COLOR,
        color: ALERT_TEXT_COLOR,
        textAlign: ALERT_TEXT_ALIGN,
        fontSize: ALERT_TEXT_SIZE,
        border: 0,
        boxShadow: '2px 2px 2px rgba(0,0,0,0.1)',
        marginBottom: '30px'
    }} >
        {message}
    </Alert>
  )
}

export default AlertComponent