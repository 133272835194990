import React, { useState } from 'react';
import Axios from 'axios';
import { Row, Col, Table } from 'react-bootstrap';
import {
    DatatableWrapper,
    Filter,
    Pagination,
    TableBody,
    TableHeader
} from "react-bs-datatable";

import { AppConstants } from '../AppConstants';
import AlertComponent from '../components/AlertComponent';
import LoadingComponent from '../components/LoadingComponent';

import ViewReports from './ViewReports';
import { logoutUser } from '../helper/global-helper';

function Dashboard() {
    const dbBaseUrl = AppConstants().db_base_url;

    const [formSubmit, setFormSubmit] = useState(false);

    // loading and message display
    const [displayMsg, setDisplayMsg] = useState(null);
    const [showLoading, setShowLoading] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState('');

    const [searchAddress, setSearchAddress] = useState('');
    const [addressList, setAddressList] = useState([]);

    const [selectAddress, setSelectAddress] = useState('');

    const STORY_HEADERS = [
        {
          prop: "client_name",
          title: "Client Name",
          isFilterable: true,
          isSortable: true
        },
        {
          prop: "address",
          title: "Address",
          isFilterable: true,
          isSortable: true
        },
        {
          prop: "cell",
          title: ""
        }
    ];

    function handleSearchAddress(){
        setFormSubmit(true);
        setSelectAddress('');
        
        if( searchAddress.trim() !== '' ){
            setDisplayMsg(null);
            setShowLoading(true);
            setLoadingMsg('Searching for address. Please wait.');

            Axios.defaults.headers.common['x-access-token'] = localStorage.getItem("token")

            Axios.post(dbBaseUrl + "citizens-search-address", {
                userId: localStorage.getItem('id'),
                searchAddress: searchAddress.trim()
            })
            .then((response) => {
                window.scrollTo(0,0);
                setShowLoading(false);
    
                if( response.data.type === 'success' ){
                    buildTable(response.data.resultData)
                } else {
                    if( response.data.code === 2 ){
                        logoutUser(response.data.message);
                    } else {
                        buildTable(null)
                        setDisplayMsg(response.data);
                    }
                }
            })
            .catch(error => console.log(error));
        } else {
            buildTable(null)
            setDisplayMsg({type: 'error', message: "Please enter an address or owner's name."})
            return
        }
    }

    const buildTable = (resData) => {
        let addressTbl = [];
        let checkAddress = [];

        if( resData !== undefined && resData !== null ){
          for( let x = 0; x < resData.length; x++ ){
            if( !checkAddress.includes(resData[x].full_address) ){
                addressTbl.push(
                    {
                      client_name: resData[x].client_firstname + ' ' + resData[x].client_lastname,
                      address: resData[x].full_address,
                      cell: 
                          <>
                              <button className='btn-primary' onClick={() => handleViewReports(resData[x].full_address)}>View Reports</button>
                          </>
                    }
                  )
                  checkAddress.push(resData[x].full_address)
            }
          }
        }
  
        setAddressList(addressTbl)
        return addressTbl;
    }

    function handleViewReports(viewAddress){
        setShowLoading(true);
        setLoadingMsg('Preparing information. Please wait.');

        Axios.defaults.headers.common['x-access-token'] = localStorage.getItem("token")

        Axios.post(dbBaseUrl + "citizens-save-view-record", {
            userId: localStorage.getItem('id'),
            searchAddress: viewAddress.trim()
        })
        .then((response) => {
            setShowLoading(false);

            if( response.data.type === 'success' ){
                setSelectAddress(viewAddress)
            } else {
                if( response.data.code === 2 ){
                    logoutUser(response.data.message);
                }
            }
        })
        .catch(error => console.log(error));
        
    }

    return (
        <div className='main-container container'>
            <h1 className='secondary bold'>Property Inspection Reports</h1>
            <hr/>

            {
                displayMsg !== null ? 
                    <AlertComponent message={displayMsg.message} color={`${displayMsg.type === 'error' ?  'danger' : displayMsg.type === 'success' ? 'success' : ''}`} textColor='primary'  />
                : null
            }

			<div className='container mt-4 border rounded p2'>
				<h2 className='text-center primary'>Search for completed property inspection reports</h2>
				<p className='text-center italic primary'>All reports are made by HIT's registered property inspectors.</p>
				
                <Row>
                    <Col
                        md={12}
                        lg={10}
                    >
                        <div className="input-group mb-3">
                            <span className="input-group-text"><i className="fa-solid fa-search"></i></span>
                            <input type="text" className={`form-control ${formSubmit && searchAddress.trim() === '' && 'is-invalid'}`} placeholder="Type address or owner's name to search inspection reports" onChange={el => setSearchAddress(el.target.value.trim())} onKeyUp={(e) => e.key === 'Enter' && handleSearchAddress()} />
                        </div>
                    </Col>
                    <Col
                        md={12}
                        lg={2}
                    >
                        <button className='btn-primary' onClick={() => handleSearchAddress()}>Search</button>
                    </Col>
                </Row>
		    </div>

            {
                selectAddress !== '' ?
                    <ViewReports data={{address: selectAddress, setSelectAddressEmpty: () => setSelectAddress('')}} />
                : 
                    addressList.length > 0 ?
                        <Row className='mt-5 mb-3'>
                            <DatatableWrapper
                                body={addressList}
                                headers={STORY_HEADERS}
                                paginationOptionsProps={{
                                    initialState: {
                                        rowsPerPage: 20,
                                    }
                                }}
                            >
                            <Row className="mb-4 p-2">
                                <Col className="d-flex flex-col justify-content-end align-items-end datatable-filter">
                                    <Filter placeholder='Type to search' />
                                </Col>
                                <Col className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0">
                                </Col>
                                <Col className="d-flex flex-col justify-content-end align-items-end">
                                    <Pagination />
                                </Col>
                            </Row>
                            <Table className='primary'>
                                <TableHeader />
                                <TableBody className='primary' />
                            </Table>
                            <Col className="d-flex flex-col justify-content-end align-items-end">
                                <Pagination />
                            </Col>
                            </DatatableWrapper>
                        </Row>
                    : null
            }

            <LoadingComponent 
                show={showLoading}
                onHide={() => setShowLoading(false)}
                message = {loadingMsg}
                redirect = 'false'
            />
        </div>
    )
}

export default Dashboard