import React, {useState, useEffect} from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Axios from 'axios';

import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';
import * as FAIcons from 'react-icons/fa6';

import './SidebarStyles.css';
import { AppConstants } from '../AppConstants';
import { setLogout } from '../features/userProfile';

function Sidebar({children}) {
    const dbBaseUrl = AppConstants().db_base_url;

    const dispatch = useDispatch();
    const[isOpen ,setIsOpen] = useState(true);
    const toggle = () => setIsOpen (!isOpen);

    const [userName, setUserName] = useState('');
    
    const menuItems = [
        {
            path: "/",
            name: "Inspection Reports",
            icon: <FAIcons.FaListCheck />
        },
        {
            path: "/view-logs",
            name: "View Logs",
            icon: <RiIcons.RiMenuSearchLine />
        },
        {
            path: "/settings",
            name: "Settings",
            icon: <FAIcons.FaGear />
        },
    ];

    const handleLogout = () => {
        dispatch(setLogout())
        localStorage.clear()
        window.location.reload()
    }

    function getUserInfo(){
        Axios.defaults.headers.common['x-access-token'] = localStorage.getItem("token")
        Axios.post(dbBaseUrl + "citizens-get-user-info", {
            userId: localStorage.getItem('id')
        })
        .then((response) => {
            if( response.data.type === 'success' ){
                setUserName(response.data.resultData[0].fullname);
            } else {
                if( response.data.code === 2 ){
                    handleLogout();
                }
            }
        })
        .catch(error => console.log(error));
    }

    useEffect(() => {
        getUserInfo();
    //eslint-disable-next-line
    }, [])

    return (
        <div className="app-container">
            <div style={{width: isOpen ? "300px" : "50px"}} className="sidebar">
                <div className="top_section">
                    <div style={{marginLeft: isOpen ? "250px" : "0px"}} className="bars mt-2 mb-3">
                        {
                            !isOpen ? <IoIcons.IoMdMenu onClick={toggle}/> : <IoIcons.IoMdClose onClick={toggle}/>
                        }
                    </div>
                </div>
                
                {
                    isOpen &&
                        <center>
                            <Link to='/'>
                                <img className='hit-citizens-logo logo' src='/citizens-logo.png' alt='citizens-logo' width={250}/>
                            </Link>

                            <p className='bold mt-3'>{userName}</p><hr/>
                        </center>
                }

                {
                    menuItems.map((item, index)=>(
                        <NavLink to={item.path} key={index} className="link" activeclassname="active">
                            <div className="icon">{item.icon}</div>
                            <div style={{display: isOpen ? "block" : "none"}} className="link_text">{item.name}</div>
                        </NavLink>
                    ))
                }

                <div className='link logout border-top-black-4 mt-4' onClick={handleLogout}>
                    <div className="icon"><RiIcons.RiLogoutBoxRLine /></div>
                    <div style={{display: isOpen ? "block" : "none"}} className="link_text">Logout</div>
                </div>
            </div>
            <main>{children}</main>
        </div>
    )
}

export default Sidebar