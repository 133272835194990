import React, {useState} from 'react';
import Axios from 'axios';

import { Image } from 'react-bootstrap';

import { AppConstants } from '../../AppConstants';
import AlertComponent from '../../components/AlertComponent';
import LoadingComponent from '../../components/LoadingComponent';

function ForgotPassword() {
    const dbBaseUrl = AppConstants().db_base_url;

    // loading and message display
    const [displayMsg, setDisplayMsg] = useState(null);
    const [showLoading, setShowLoading] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState('');

    const [formSubmit, setFormSubmit] = useState(false);
    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    function handleResetPassword(){
        setFormSubmit(true);
        setShowLoading(true);
        setLoadingMsg('Sending link to email. Please wait.')
        
        if( email === '' || !AppConstants().emailRegExp.test(email) ){
            setDisplayMsg({type: 'error', message: 'Please enter a valid email address.'})
            setShowLoading(false);
            return
        }

        Axios.post(dbBaseUrl + "citizens-forgot-password-request", {
            email: email
        })
        .then((response) => {
            setFormSubmit(false);
            setDisplayMsg(response.data);
            setShowLoading(false);
            setEmailSent(true);

            setTimeout(() => {
                setEmailSent(false);
            }, [180000])
        })
        .catch(error => console.log(error));
    }

    return (
        <div className='main-container content-centered container'>
            <Image src='/citizens-logo.png' className='mb-4' height={100} />

            <div id='login-container'>
                <h1 className='primary'>Forgot Password</h1>

                {
                    displayMsg !== null ? 
                        <AlertComponent message={displayMsg.message} color={`${displayMsg.type === 'error' ?  'danger' : displayMsg.type === 'success' ? 'success' : ''}`} textColor='primary'  />
                    : null
                }

                <div className="input-group mb-3 mt-4">
                    <span className="input-group-text"><i className="fa-solid fa-envelope"></i></span>
                    <input type="email" className={`form-control ${((email !== '' && !AppConstants().emailRegExp.test(email)) || (formSubmit && email === '') ) && 'is-invalid'}`} placeholder="Email address" onBlur={el => setEmail(el.target.value.trim())} aria-label="Email address" />
                </div>

                {
                    emailSent ?
                        <button className='btn disabled mt-2'>Send link to reset password</button>
                    : 
                        <button className='btn-primary mt-2' onClick={() => handleResetPassword()}>Send link to reset password</button>
                }
            </div>

            <LoadingComponent 
                show={showLoading}
                onHide={() => setShowLoading(false)}
                message = {loadingMsg}
                redirect = 'false'
            />
        </div>
    )
}

export default ForgotPassword