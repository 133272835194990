import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Image } from 'react-bootstrap';

import {AppConstants} from '../../AppConstants';
import AlertComponent from '../../components/AlertComponent';
import LoadingComponent from '../../components/LoadingComponent';

function LoginPage() {
    const dbBaseUrl = AppConstants().db_base_url;
    const queryParams = new URLSearchParams(window.location.search);
    
    // loading and message display
    const [displayMsg, setDisplayMsg] = useState(null);
    const [showLoading, setShowLoading] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState('');

    const [formSubmit, setFormSubmit] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    function handleLogin(){
        setFormSubmit(true);
        setShowLoading(true);
        setLoadingMsg('Authenticating login informaiton. Please wait.');

        if( email === '' || !AppConstants().emailRegExp.test(email) ){
            setDisplayMsg({type: 'error', message: 'Please enter a valid email address.'})
            setShowLoading(false);
            return
        }
        if( password === ''){
            setDisplayMsg({type: 'error', message: 'Please enter your password.'})
            setShowLoading(false);
            return
        }

        handleSubmitLogin();
    }

    function handleSubmitLogin(){
        setShowLoading(true);
        setLoadingMsg('Logging in. Please wait.');

        Axios.post(dbBaseUrl + "citizens-login", {
            email: email.trim(),
            password: password.trim()
        })
        .then((response) => {
            window.scrollTo(0,0);
            setDisplayMsg(response.data);
            setShowLoading(false);

            if( response.data.type === 'success' ){
                localStorage.setItem("id", response.data.userId);
                localStorage.setItem("token", response.data.accessToken)

                setTimeout(() => {
                    window.location.replace('/');
                }, 1500)
            }
        })
        .catch(error => console.log(error));
    }

    useEffect(() => {
        if( queryParams.get('session') !== '' ){
            if( queryParams.get('session') === 'unauthorized' ){
                setDisplayMsg({type: 'error', message: "Unauthorized session. Please login again."})
            } else if( queryParams.get('session') === 'expired' ){
                setDisplayMsg({type: 'error', message: "Session expired. Please login again."})
            }
        }
    //eslint-disable-next-line
    }, [])

    return (
        <div className='main-container content-centered container'>
            <Image src='/citizens-logo.png' className='mb-4' height={100} />

            <div id='login-container'>
                <h1 className='primary'>Login</h1>
                <p className='italic primary'>This login is only for Florida Citizens Insurance Property Inspection reprensentatives.</p>
                
                {
                    displayMsg !== null ? 
                        <AlertComponent message={displayMsg.message} color={`${displayMsg.type === 'error' ?  'danger' : displayMsg.type === 'success' ? 'success' : ''}`} textColor='primary'  />
                    : null
                }

                <form className='mb-2' onKeyUp={(e) => e.key === 'Enter' && handleSubmitLogin()}>
                    <div className="input-group mb-3">
                        <span className="input-group-text"><i className="fa-solid fa-envelope"></i></span>
                        <input type="email" className={`form-control ${((email !== '' && !AppConstants().emailRegExp.test(email)) || (formSubmit && email === '') ) && 'is-invalid'}`} placeholder="Email address" onBlur={el => setEmail(el.target.value.trim())} aria-label="Email address" />
                    </div>
                    <div className="input-group">
                        <span className="input-group-text"><i className={`fa-solid ${showPassword ? 'fa-eye' : 'fa-lock'}`}></i></span>
                        <input type={showPassword ? 'text' : 'password'} className={`form-control ${formSubmit && password === '' && 'is-invalid'}`} onChange={el => setPassword(el.target.value.trim())} placeholder="Password" aria-label="Password" autoComplete="on" />
                    </div>
                </form>

                <a href='/forgot-password' className='primary italic' >Forgot Password</a>
                <p className='primary pointer text-right italic mt-2' onClick={(e) => setShowPassword(!showPassword)} >Show Password</p>
                <button className='btn-primary mt-2' onClick={() => handleLogin()}>Login</button>
            </div>

            <LoadingComponent 
                show={showLoading}
                onHide={() => setShowLoading(false)}
                message = {loadingMsg}
                redirect = 'false'
            />
        </div>
    )
}

export default LoginPage